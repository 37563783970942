import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { DOCUMENT } from '@angular/common'; 
import { AnimationOptions } from 'ngx-lottie';
import { SampleService } from './sample.service';
import { SignalRService } from '@fuse/services/signal-r.service';
import { DriverLocation } from './DriverLocation';

@Component({
    selector   : 'app-sample',
    templateUrl: './sample.component.html',
    styleUrls  : ['./sample.component.scss']
})
export class SampleComponent implements OnInit, OnDestroy
{
    AllBenfites:any =[];
    BestDriver : any =[];
    count:any =[];
    data : any =[];
    options: AnimationOptions = {
        path: 'assets/icons/73493-golden-award-eternal-expression.json',
      };
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    doc:any;
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _SampleService:SampleService,
        private signalRService: SignalRService,

        @Inject(DOCUMENT) document
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.doc=document;
        document.getElementById('main').style="background: url('/assets/images/backgrounds/Group 1401.png') no-repeat !important;";
 
    }
    ngOnInit(): void
    {
        this._SampleService.BenfitesChanged.subscribe((response:any =[]) => {
            this.AllBenfites = response;
         });
         this._SampleService.BestDriverChanged.subscribe((response:any =[]) => {
            this.BestDriver = response;
         })
         this._SampleService.NewClientsCountChanged.subscribe((response:any =[]) => {
            this.count = response;
         })


          this.signalRService.StartConnection();
          this.subscribeToCloseConnection();
          this.subscribeToCloseConnection1();
          this.subscribeToCloseConnection2();
          this.subscribeToCloseConnection3();
          let     id = sessionStorage.getItem('id') || localStorage.getItem('id');
          setTimeout(() => this.signalRService.checkconnection(+id),
         5000 );

         this.signalRService.onlineList.subscribe((response:any) => {
         })
         
    }
    private subscribeToCloseConnection(): void {
        this.signalRService.LogOut1.subscribe((message1: any) => {
        });
    }

  
    private subscribeToCloseConnection1(): void {
        this.signalRService.start.subscribe((message1: any) => {
        });
    }
    private subscribeToCloseConnection2(): void {
        this.signalRService.cancel.subscribe((message1: any) => {
        });
    }
    private subscribeToCloseConnection3(): void {
        this.signalRService.end.subscribe((message1: any) => {
        });
    }
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this.doc.getElementById('main').style="";
    }
}
