<ng-container *ngIf="!item.hidden">

    <div class="group-title" [ngClass]="item.classes">
    </div>

    <div class="group-items">
        <ng-container *ngFor="let item of item.children">
        
            <fuse-nav-vertical-item *ngIf="item.type=='item'" [item]="item"></fuse-nav-vertical-item>


        </ng-container>
    </div>
    
  
</ng-container>
