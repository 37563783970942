<div class="page-layout blank p-24">
    <div class="box-add-reason" fxLayout="column">
      <!-- header -->
      <div class="header-card" fxLayout="row" fxLayoutAlign="space-around center">
        <h1>{{confirmMessage}}</h1>
      </div>

      <!-- add button -->
      <div fxLayout="column">
        <button class="btn-add-reason" (click)="dialogRef.close(true)">تاكيد</button>
        <button class="btn-cancel-reason" (click)="dialogRef.close(false)">الغاء</button>
      </div>
    </div>
  </div>
  