import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetAllBenfites, GetBestDriver, GetNewClientsCount } from 'URL_API';

@Injectable({
  providedIn: 'root'
})
export class SampleService implements Resolve<any>{

  token: any = sessionStorage.getItem('key') || localStorage.getItem('key');
  option = { headers: { 'content-Type': 'application/json', Authorization: 'Bearer ' + this.token } };
  Benfites : any;
  BenfitesChanged: BehaviorSubject<any>;

  BestDriver : any;
  BestDriverChanged: BehaviorSubject<any>;

  NewClientsCount : any;
  NewClientsCountChanged: BehaviorSubject<any>;
  constructor(
    private _httpClient: HttpClient
  ) {
    this.BenfitesChanged = new BehaviorSubject({});
    this.BestDriverChanged = new BehaviorSubject({});
    this.NewClientsCountChanged = new BehaviorSubject({});

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {

      Promise.all([
        this.getAllBenfites(),
        this.GetBestDriver(),
        this.GetNewClientsCount()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }
  getAllBenfites(): Promise<any> {
    return new Promise((resolve, reject) => {

      this._httpClient.get(GetAllBenfites, this.option)
        .subscribe((response: any) => {
          this.Benfites = response.result;
          this.BenfitesChanged.next(this.Benfites);
          resolve(response.result);
        }, reject);
    });
  }
  GetBestDriver(): Promise<any> {
    return new Promise((resolve, reject) => {

      this._httpClient.get(GetBestDriver, this.option)
        .subscribe((response: any) => {
          this.BestDriver = response.result;
          this.BestDriverChanged.next(this.BestDriver);
          resolve(response.result);
        }, reject);
    });
  }
  GetNewClientsCount(): Promise<any> {
    return new Promise((resolve, reject) => {

      this._httpClient.get(GetNewClientsCount, this.option)
        .subscribe((response: any) => {
          this.NewClientsCount = response.result;
          this.NewClientsCountChanged.next(this.NewClientsCount);
          resolve(response.result);
        }, reject);
    });
  }
}
