<div class="page-layout blank " ngClass="p-20" ngClass.lt-md="p-10"  ngClass.lt-lg="p-60" ngClass.gt-lg="p-60" >
    <div >
        <div class="Tuqaadiv Tuqaabackground  " fxLayout="column" >
            <div fxFlex="20" class="homeheader">
                <h2>الربح الإجمالي</h2>
            </div>
            <div fxFlex="80" > 
                <table class="table hometable " id="myTable" >
                    <thead>
                        <tr>
                            <th>
                                اليوم
                            </th>
                            <th>
                                البارحة
                            </th>
                            <th>
                                آخر سبع أيام
                            </th>
                            <th>
                                الشهر الحالي
                            </th>
                            <th>
                                الشهر الماضي
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{AllBenfites.benefits_all.valuse_of_Today | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_all.valuse_Of_Yestrday | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_all.valuse_Of_week | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_all.valuse_Of_month | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_all.valuse_Of_Last_month | currency:' '}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="Tuqaadiv Tuqaabackground mt-1" fxLayout="column" >
            <div fxFlex="20" class="homeheader">
                <h2>الربح الصافي للشركة</h2>
            </div>
            <div fxFlex="80">
                <table class="table hometable " id="myTable">
                    <thead>
                        <tr>
                            <th>
                                اليوم
                            </th>
                            <th>
                                البارحة
                            </th>
                            <th>
                                آخر سبع أيام
                            </th>
                            <th>
                                الشهر الحالي
                            </th>
                            <th>
                                الشهر الماضي
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{AllBenfites.benefits_Company.valuse_of_Today | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Company.valuse_Of_Yestrday | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Company.valuse_Of_week | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Company.valuse_Of_month | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Company.valuse_Of_Last_month | currency:' '}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="Tuqaadiv Tuqaabackground mt-1" fxLayout="column" >
            <div fxFlex="20" class="homeheader">
                <h2> ربح السائقين الصافي</h2>
            </div>
            <div fxFlex="80">
                <table class="table hometable " id="myTable">
                    <thead>
                        <tr>
                            <th>
                                اليوم
                            </th>
                            <th>
                                البارحة
                            </th>
                            <th>
                                آخر سبع أيام
                            </th>
                            <th>
                                الشهر الحالي
                            </th>
                            <th>
                                الشهر الماضي
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{AllBenfites.benefits_Driver.valuse_of_Today | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Driver.valuse_Of_Yestrday | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Driver.valuse_Of_week | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Driver.valuse_Of_month | currency:' '}}
                            </td>
                            <td>
                                {{AllBenfites.benefits_Driver.valuse_Of_Last_month | currency:' '}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div fxLayout="row" class="mt-1" fxLayout.lt-md="column">
            <div fxFlex="65">
                <div class="Tuqaadiv Tuqaabackground mt-1" fxFlex="100" fxLayout="row">
                    <div fxFlex="100" fxLayout="column">
                        <div fxFlex="40" ngClass.gt-md="homeheader1">
                            <h2 class="center">أفضل سائق</h2>
                        </div>
                        <div fxFlex="100">
                            <div fxFlex="50" fxFlex.lt-md="33">
                                <div class="center" ngClass.gt-md="homeheader1">
                                    <h3 > عدد الرحلات </h3>
                                    <p>{{BestDriver.count}}</p>
                                </div>
                            </div>
                            <div class="center"  fxFlex="15" fxFlex.lt-md="33">
                                <mat-icon fxHide.lt-md svgIcon="users" class="notranslate User-icon "></mat-icon>
                                <h3> {{BestDriver.name}}</h3>

                            </div>
                            <div fxFlex="35" fxFlex.lt-md="33">
                                <div class="center" ngClass.gt-md="homeheader1">
                                    <h3> عدد الساعات </h3>
                                    <p>{{BestDriver.clocks}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div fxHide.lt-md fxFlex="18">
                        <ng-lottie [options]="options"> </ng-lottie>

                    </div>
                </div>
            </div>
            <div fxFlex="35" class="mr-3">
                <div class="Tuqaadiv Tuqaabackground mt-1" fxLayout="column" fxFlex="100">
                    <div fxFlex="30" ngClass.gt-md="homeheader">
                        <h2 class="center"> العملاء الجدد </h2>
                    </div>
                    <div fxFlex="80">
                        <div fxFlex="60" fxFlex.lt-md="100">
                            <div class="center" ngClass.gt-md="homeheader">
                                <h3> العملاء الجدد </h3>
                                <p>{{count}}</p>
                            </div>
                        </div>
                        <div  fxHide.lt-md fxFlex="40">
                            <mat-icon  svgIcon="users" class="notranslate User-icon "></mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>