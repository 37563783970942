export const header = { headers: { 'content-Type': 'application/json' } }
import * as signalR from '@microsoft/signalr';  

//export const Api = 'https://localhost:44311';
export const Api = 'https://test.tuqaatech.com';
//export const Api = 'https://magic-car-api.magiccartransportation.com';
//export const Api = 'http://192.168.5.140:2020';



/////////////////// Auth /////////////////////////////////////////
export const login = Api + '/api/TokenAuth/Authenticate';
export const forgetPassword = Api + '/api/services/app/Account/ForgetPassword';
export const restPassword = Api + '/api/services/app/Account/ResetNewPassword';

export const VerifyEmail = Api + '/api/services/app/Account/VerifyEmail?token=';
export const ResetPassword = Api + '/api/services/app/Account/ResetNewPassword';
export const CheckingTenant = Api + '/api/services/app/Account/IsTenantAvailable';


/////////////////////////////////




///////////////////// notfications //////////////
export const GetNotfications =  Api + '/api/services/app/NotificationsAppServices/GetAllNotifications';
///////////////////// DriverWallets //////////////
export const DriverWalletsGetAll =  Api + '/api/services/app/DriverWallet/GetAllWallets';
export const DriverWalletsInsert =  Api + '/api/services/app/DriverWallet/Create';
export const GetAllUsers0 =  Api + '/api/services/app/User/GetAllUsers';
export const GetAllDriversForDriverWallet = Api + '/api/services/app/DriverWallet/GetAllDriversForDriverWallet';


///// notfiaction
export const CreateNotfiacation =  Api + '/api/services/app/NotificationsAppServices/createNotfcation';


//Tables Api
export const TablesApi =  Api + '/api/services/app/TablesService/GetAll';
export const DeleteTableApi =  Api + '/api/services/app/TablesService/Delete?Id=';
export const UpdateTableApi =  Api + '/api/services/app/TablesService/Update';
export const GetTableApi =  Api + '/api/services/app/TablesService/Get?Id=';
export const CreateTableApi =  Api + '/api/services/app/TablesService/Create';
export const TableImageApi =  Api +'/api/services/app/TablesService/GetDownloadImage?id=';

// Suggestion Api
export const SuggestionsApi =  Api + '/api/services/app/SuggestionsService/GetAll';
export const DeleteSuggestionApi =  Api + '/api/services/app/SuggestionsService/Delete?Id=';

// Driver Ruqest 
export const DownloadImage_ID_FrontFace =  Api + '/api/services/app/User/DownloadImage_ID_FrontFace?id=';
export const DownloadImage_ID_BackFace =  Api + '/api/services/app/User/DownloadImage_ID_BackFace?id=';
export const DownloadImage_DriverLicense_frontFace =  Api + '/api/services/app/User/DownloadImage_DriverLicense_frontFace?id=';
export const DownloadImage_DriverLicense_backFace =  Api + '/api/services/app/User/DownloadImage_DriverLicense_backFace?id=';
export const DownloadImage_Personal_Photo =  Api + '/api/services/app/User/DownloadImage_Personal_Photo?id=';
export const DownloadImage_CarNumber_photo =  Api + '/api/services/app/User/DownloadImage_CarNumber_photo?id=';
export const DownloadImage_Car_Photo =  Api + '/api/services/app/User/DownloadImage_Car_Photo?id=';
export const DownloadImage_UploadId_Car_papers =  Api + '/api/services/app/User/DownloadImage_UploadId_Car_papers?id=';
export const DownloadImage_UploadId_Unconvicted_photo =  Api + '/api/services/app/User/DownloadImage_UploadId_Unconvicted_photo?id=';

export const GetDriverPapers = Api + '/api/services/app/User/GetDriverPapers';
export const UserDriverRequst =  Api + '/api/services/app/User/GetAllDriversEmploymentRequest';
export const RejectDriverRequst =  Api + '/api/services/app/User/Delete?Id=';
export const ApproveDriverRequst =  Api + '/api/services/app/User/AcceptDriver';


//Languages Api
export const LanguagesApi =  Api + '/api/services/app/Language/GetAllLanguages';
export const DeleteLanguageApi =  Api + '/api/services/app/Language/Delete?Id=';
export const UpdateLanguageApi =  Api + '/api/services/app/Language/Update';
export const GetLanguageApi =  Api + '/api/services/app/Language/Get?Id=';
export const CreateLanguageApi =  Api + '/api/services/app/Language/Create';

//Resturnet_infos Api
export const Resturnet_infosApi =  Api + '/api/services/app/RestaurantService/GetAll';
export const DeleteResturnet_infoApi =  Api + '/api/services/app/RestaurantService/Delete?Id=';
export const UpdateResturnet_infoApi =  Api + '/api/services/app/RestaurantService/Update';
export const GetResturnet_infoApi =  Api + '/api/services/app/RestaurantService/Get?Id=';
export const CreateResturnet_infoApi =  Api + '/api/services/app/RestaurantService/Create';
export const Resturnet_infoImageApi =  Api +'/api/services/app/RestaurantService/GetDownloadImage?id=';



//Application Users Api
export const AppusersApi =  Api + '/api/services/app/RestaurantService/GetAll';
export const DeleteAppuserApi =  Api + '/api/services/app/RestaurantService/Delete?Id=';
export const UpdateAppuserApi =  Api + '/api/services/app/RestaurantService/Update';
export const GetAppuserApi =  Api + '/api/services/app/RestaurantService/Get?Id=';
export const CreateAppuserApi =  Api + '/api/services/app/RestaurantService/Create';
export const AppuserImageApi =  Api +'/api/services/app/RestaurantService/GetDownloadImage?id=';

//// Cancel Reasons
export const GetCancelReason =  Api + '/api/services/app/CancelReasons/Get?Id=';
//export const GetAllCancelReason =  Api + '/api/services/app/CancelReasons/GetAll';
export const GetAllCancelReasonِAdmin =  Api + '/api/services/app/CancelReasons/GetAllCancelReasonForAdmin';
export const CreateCancelReason =  Api +'/api/services/app/CancelReasons/Create'
export const UpdateCancelReason =  Api + '/api/services/app/CancelReasons/Update';
export const DeleteCancelReason =  Api + '/api/services/app/CancelReasons/Delete?Id=';

//// Coupons
export const GetCoupons =  Api + '/api/services/app/Coupons/Get?Id=';
export const GetAllCoupons =  Api + '/api/services/app/Coupons/GetAll';
export const CreateCoupons =  Api +'/api/services/app/Coupons/Create'
export const UpdateCoupons =  Api + '/api/services/app/Coupons/Update';
export const DeleteCoupons =  Api + '/api/services/app/Coupons/Delete?Id=';
export const changeStatusCoupons =  Api + '/api/services/app/Coupons/ChangeStatusOfCoupon?Id=';

//// CarCategoriesService
export const GetCarCategoriesService =  Api + '/api/services/app/CarCategoriesService/Get?Id=';
export const GetAllCarCategoriesService =  Api + '/api/services/app/CarCategoriesService/GetAllCarCategories';
export const CreateCarCategoriesService =  Api +'/api/services/app/CarCategoriesService/Create'
export const UpdateCarCategoriesService =  Api + '/api/services/app/CarCategoriesService/Update';
export const DeleteCarCategoriesService =  Api + '/api/services/app/CarCategoriesService/Delete?Id=';
//export const CarCategoryImageApi =  Api +'/api/services/app/CarCategoriesService/GetDownloadImage?id=';

//// Order
export const GetAllOrder =  Api + '/api/services/app/Order/GetAllTrips';


//System Users Api
export const SysusersApi =  Api + '/api/services/app/User/GetAll';
export const SysUsersRoles= Api +'/api/services/app/User/GetRoles';
export const messagesApi = Api + '/api/services/app/Messages/GetAll';
export const changePassword = Api + '/api/services/app/User/ChangePassword';
export const DeleteSysuserApi =  Api + '/api/services/app/User/Delete?Id=';
export const UpdateSysuserApi =  Api + '/api/services/app/User/Update';
export const GetSysuserApi =  Api + '/api/services/app/User/Get?Id=';
export const CreateSysuserApi =  Api + '/api/services/app/User/Create';
export const SysuserImageApi =  Api +'/api/services/app/User/GetDownloadImage?id=';
export const userImageApi = Api +'/api/services/app/User/DownloadUserAvatar?id=';
export const IsUserAvatar = Api +'/api/services/app/User/IsUesrAvatar?id=';

//Roles
export const RolesApi =  Api + '/api/services/app/Role/GetAll';
export const DeleteRoleApi =  Api + '/api/services/app/Role/Delete?Id=';
export const PermissionsApi =  Api +'/api/services/app/Role/GetAllPermissions'

export const UpdateRoleApi =  Api + '/api/services/app/Role/Update';
export const GetRoleApi =  Api + '/api/services/app/Role/Get?Id=';
export const CreateRoleApi =  Api + '/api/services/app/Role/Create';

//items
export const GetItems =  Api + '/api/services/app/Item/GetAllItems';
export const DeleteItemsApi =  Api + '/api/services/app/Item/Delete?Id=';
export const UpdateItemsApi =  Api + '/api/services/app/Item/Update';
export const GetItemApi =  Api + '/api/services/app/Item/Get?Id=';
export const CreateItemsApi =  Api + '/api/services/app/Item/Create';


//Settings
export const GetAllSettigs =  Api + '/api/services/app/Setings/getAllContactsforAdmin';
export const EditeAllSettigs =  Api + '/api/services/app/Setings/EditeContactsforAdmin';


//Drivers
export const Getdrivers =  Api + '/api/services/app/User/GetAllDrivers';
export const UpdateCarType =Api+'/api/services/app/User/UpdateCarType'
export const DeletedriverApi =  Api + '/api/services/app/User/Delete?Id=';
export const UpdatedriverApi =  Api + '/api/services/app/User/Update';
export const GetdriverApi =  Api + '/api/services/app/User/Get?Id=';
export const CreatedriverApi =  Api + '/api/services/app/User/CreateDriver';
export const GetAllDriverLog =  Api + '/api/services/app/User/GetAllDriverLog';
export const GetAllDriverLogById =  Api + '/api/services/app/User/GetAllDriverLogById?id=';
export const UpdatedriverWithCarTypeApi = Api + '/api/services/app/User/UpdateDriverWithCarType';

export const GetUserBalance =  Api + '/api/services/app/User/GetBalanceByUserId?userId=';
export const payDriver =  Api + '/api/services/app/User/payDriver';
export const PayDriverbonus =  Api + '/api/services/app/User/paybonusForDriver';

//Customers
export const GetCustomers =  Api + '/api/services/app/User/GetAllCustomars';
export const DeleteCustomerApi =  Api + '/api/services/app/User/Delete?Id=';
export const ActiveCustomerApi =  Api + '/api/services/app/User/Activate';
export const DeActiveCustomerApi =  Api + '/api/services/app/User/DeActivate';

export const UpdateCustomerApi =  Api + '/api/services/app/User/Update';
export const GetCustomerApi =  Api + '/api/services/app/User/Get?Id=';
export const CreateCustomerApi =  Api + '/api/services/app/User/Create';
/////////////////////////////////////////////////
export const GetAllUsers = Api + '/api/services/app/User/GetAll';
export const GetUser = Api +'/api/services/app/User/Get?Id=';
export const DeleteUser = Api +'/api/services/app/User/Delete?Id=';
export const DeleteUserDish = Api +'/api/services/app/User/DeleteDish?Id=';
export const CreateUserAdmin = Api +'/api/services/app/User/Create';
export const UpdateUserAdmin = Api +'/api/services/app/User/Update';
export const ChangePassword = Api +'/api/services/app/User/ChangePassword';

export const ActiveUserApi =  Api + '/api/services/app/User/Activate';
export const DeActiveUserApi =  Api + '/api/services/app/User/DeActivate';
export const SignalRApi = Api + '/Taxi/';
////////////////// Sample /////////////////////
export const GetAllBenfites = Api + '/api/services/app/User/GetAllBenfites';
export const GetBestDriver = Api + '/api/services/app/User/GetBestDriver';
export const GetNewClientsCount = Api + '/api/services/app/User/GetNewClientsCount';


export const hubconnection = new signalR.HubConnectionBuilder().withUrl(SignalRApi).build();


