import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [

    {

        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            {
                id: 'sample',
                title: 'Sample',
                translate: 'لوحة تحكم',
                type: 'item',
                svgIcon: 'home_Sidenav',
                url: '/sample',
            },

        ]
    },
    {
        id: 'Customers',
        title: 'Customers',
        translate: 'الزبائن',
        role: 'Pages.Customers',
        type: 'item',
        svgIcon: 'users_Sidenav',
        url: '/apps/Customers',
    },
    {
        id: 'mangement',
        title: 'mangement',
        translate: 'إدارة الرحلات',
        type: 'collapsable',
        role: 'Admin',
        children: [
            {
                id: 'car',
                title: 'car',
                translate: 'الرحلات',
                type: 'item',
                role: 'Pages.Trips',
                svgIcon: 'car_Sidenav',
                url: '/apps/Trips',
    
            },
            {
                id: 'coupon',
                title: 'coupon',
                translate: 'اكواد الحسم',
                type: 'item',
                role: 'Pages.Coupon',
                svgIcon: 'moneyoff_Sidenav',
                url: '/apps/coupons',
    
            },
            {
                
                id: 'reason',
                title: 'reason',
                translate: 'اسباب الغاء الرحلة',
                type: 'item',
                role: 'Pages.Reason',
                svgIcon: 'question_Sidenav',
                url: '/apps/reasons',
    
            }
        ]
    },
    {
        id: 'DriverMangement',
        title: 'DriverMangement',
        translate: 'ادارة السائقين',
        type: 'collapsable',
        role: 'Admin',
        children: [
            {
                id: 'DriversRequest',
                title: 'DriversRequest',
                translate: 'طلبات السائقين',
                role: 'Pages.Drivers_request',
                type: 'item',
                svgIcon: 'driver_Sidenav',
                url: '/apps/DriversRequest',
            },
            {
                id: 'drivers',
                title: 'drivers',
                role: 'Pages.Drivers',
                translate: 'السائقين',
                type: 'item',
                svgIcon: 'driver_Sidenav',
                url: '/apps/drivers',
            },
            {
                id: 'DriversWallet',
                title: 'DriversWallet',
                translate: 'التحويلات النقدية',
                role: 'Pages.Driver_wallets',
                type: 'item',
                svgIcon: 'driver_Sidenav',
                url: '/apps/DriversWallet',
            },

    ]
    },
    {
        id: 'Sittings',
        title: 'Sittings',
        translate: 'قنوات التواصل',
        type: 'collapsable',
        role: 'Admin',
        children: [
        {
            id: 'Notfications',
            title: 'Notfications',
            translate: 'الاشعارات',
            role: 'Pages.Notifications',
            type: 'item',
            svgIcon: 'notfivation',
            url: '/apps/Notfications',

        },
        {
            id: 'messages',
            title: 'messages',
            translate: 'الرسائل',
            role: 'Pages.Messages',
            type: 'item',
            svgIcon: 'message_Sidenav',
            url: '/apps/messages',

        },
        ]
    },
    {
        id: 'usersMangement',
        title: 'usersMangement',
        translate: 'الاعدادات العامة',
        type: 'collapsable',
        role: 'Admin',
        children: [
            {
                id: 'Sysusers',
                title: 'Sysusers',
                translate: 'مستخدمين لوحة التحكم',
                type: 'item',
                role: 'Pages.Users',
                svgIcon: 'administrators_Sidenav',
                url: '/apps/Sysusers',
            },
            {
                id: 'department',
                title: 'department',
                translate: 'تصنيف المركبة',
                type: 'item',
                role: 'Pages.Department',
                svgIcon: 'categories_Sidenav',
                url: '/apps/Catgories',
            },
            {
            id: 'Roles',
            title: 'Roles',
            translate: 'الأدوار',
            type: 'item',
            role: 'Pages.Roles',
            svgIcon: 'role_Sidenav',
            url: '/apps/Roles',
        },
        {
            id: 'settings',
            title: 'settings',
            translate: 'الاعدادات',
            role: 'Pages.Setting',
            type: 'item',
            svgIcon: 'administrator-solid',
            url: '/apps/settings',

        }
    ]
},
 
    {
        id: 'reports',
        title: 'reports',
        translate: 'التقارير',
        role: 'Pages.Reports',
        type: 'item',
        svgIcon: 'report_Sidenav',
        url: '/apps/reports',

    },

];
