
import { Event, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';

import { login, forgetPassword, restPassword, VerifyEmail,  ChangePassword, CheckingTenant } from './../../../../../URL_API';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';
import { userDetails } from 'app/main/Modules/userDetails';
import { userforRestPassword } from 'app/main/Modules/userforRestPassword';
import { userForgetPassword } from 'app/main/Modules/userForgetPassword';
import { userResponse } from 'app/main/Modules/userResponse';
import { userForLogin } from 'app/main/Modules/userForLogin';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    cookieDetails : any;
    constructor(private http: HttpClient,
        private router: Router
    ) {   
        if(sessionStorage.getItem('_ktTw')){
            this.token =  sessionStorage.getItem('_ktTw');
            const bytes1  = CryptoJS.AES.decrypt(this.token, 'gsKxGZ012HLL3MI5');
            const originalText = bytes1.toString(CryptoJS.enc.Utf8);
            this.cookieDetails = JSON.parse(originalText);
            this.token = this.cookieDetails.accessToken;
        }
    }
    option = { headers: { 'content-Type': 'application/json' } };
    UserDetails: userDetails;
    token:any;
    optionWithToken :any;


    login(userForLogin: userForLogin,tenantId) {
       let option = { headers: {'Abp.TenantId': ''+tenantId,'Content-Type': 'application/json'} };
        return this.http.post<userResponse>(login, userForLogin,option);
    }
    forgetPassword(userForgetPassword: userForgetPassword) {
        return this.http.post(forgetPassword, userForgetPassword, this.option);
    }
    restPassword(userforRestPassword: userforRestPassword) {
        return this.http.post(restPassword, userforRestPassword, this.option);
    }
 
    isloggedIn() {
        try {
            if (sessionStorage.getItem('key') || localStorage.getItem('key')) {

                const token = sessionStorage.getItem('avatar').toString() || localStorage.getItem('avatar').toString();
                const bytes1  = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
                const originalText = bytes1.toString(CryptoJS.enc.Utf8);
                const helper = new JwtHelperService();
                const isExpired = helper.isTokenExpired(originalText);
                if (isExpired) {
                    this.router.navigate(['/pages/auth/login']);
                    return false;
                }
                else {
                    return true;
                }
            }
            else { return false; }
        }
        catch (error) {
            this.router.navigate(['/pages/auth/login']);
            return false;
        }
    }
    currentUrl : string;
    isAdmin() {
        try {
            if (sessionStorage.getItem('key') || localStorage.getItem('key')) {
                const token = sessionStorage.getItem('avatar')?.toString() || localStorage.getItem('avatar')?.toString();
                const bytes1  = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
                const originalText = bytes1.toString(CryptoJS.enc.Utf8);
                const helper = new JwtHelperService();
                const decodedToken = helper.decodeToken(originalText);
                const isExpired = helper.isTokenExpired(originalText);
                console.log(decodedToken);
                if (isExpired) {
                    console.log(isExpired,'isExpired');
                    this.router.navigate(['/pages/auth/login']);
                    return false;
                }
                else {
                    return true;
                }
            }
            else {
                console.log('not key');
                return false;
            }
        }
        catch (error) {
            console.log(error,'error');
            this.router.navigate(['/pages/auth/login']);
            return false;
        }
    }
    isSysAdmin() {
        try {
            if (sessionStorage.getItem('key') || localStorage.getItem('key')) {
                const token = sessionStorage.getItem('avatar')?.toString() || localStorage.getItem('avatar')?.toString();
                const bytes1  = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
                const originalText = bytes1.toString(CryptoJS.enc.Utf8);
                const helper = new JwtHelperService();
                const decodedToken = helper.decodeToken(originalText);
                const isExpired = helper.isTokenExpired(originalText);
                if (isExpired) {
                    this.router.navigate(['/pages/auth/login']);
                    return false;
                }
                else {
              
                    if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Sysadmin') {
                        return true;

                    }
                    else {
                        sessionStorage.removeItem('key');
                        localStorage.removeItem('key');
                        return false;

                    }
                }
            }
            else { return false; }
        }
        catch (error) {
            this.router.navigate(['/pages/auth/login']);
            return false;
        }
    }
    isAdmin1() {
        try {
            if (sessionStorage.getItem('key') || localStorage.getItem('key')) {
                const token = sessionStorage.getItem('avatar')?.toString() || localStorage.getItem('avatar')?.toString();
                const bytes1  = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
                const originalText = bytes1.toString(CryptoJS.enc.Utf8);
                const helper = new JwtHelperService();
                const decodedToken = helper.decodeToken(originalText);
                const isExpired = helper.isTokenExpired(originalText);
                if (isExpired) {
                    this.router.navigate(['/pages/auth/login']);
                    return false;
                }
                else {
                    if (decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] === 'Admin') {
                        return true;

                    }
                    else {
                        return false;

                    }
                }
            }
            else { return false; }
        }
        catch (error) {
            this.router.navigate(['/pages/auth/login']);
            return false;
        }
    }
    // tslint:disable-next-line:typedef
    userDetails() { 
        if (sessionStorage.getItem('key') || localStorage.getItem('key')) {
            const token = sessionStorage.getItem('avatar')?.toString() || localStorage.getItem('avatar')?.toString();
            const bytes1  = CryptoJS.AES.decrypt(token, 'gsKxGZ012HLL3MI5');
            const originalText = bytes1.toString(CryptoJS.enc.Utf8);
            const helper = new JwtHelperService();
            const decodedToken: any = helper.decodeToken(originalText);
            this.UserDetails = new userDetails(decodedToken.id, decodedToken.firstName, decodedToken.lastName
                , decodedToken.fullName, decodedToken.email, decodedToken['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
                , decodedToken.created, sessionStorage.getItem('avatar')?.toString() || localStorage.getItem('avatar')?.toString(),
                 decodedToken.isVerified, decodedToken.PhoneNumber, decodedToken.Location,JSON.parse(decodedToken.Permissions))
            return this.UserDetails;
        }
        else {
            return;
        }

    }
    logout() {
        localStorage.removeItem('key');
        localStorage.removeItem('avatar');
        localStorage.removeItem('id');

        sessionStorage.removeItem('key');
        sessionStorage.removeItem('avatar');
        sessionStorage.removeItem('id');
        location.reload();
    }
    VerifyEmail(token) {
      return  this.http.get(VerifyEmail+token,this.option);
    }

    ResetPassword(formChangePassword){
        const token = sessionStorage.getItem('key') || localStorage.getItem('key');
        this.optionWithToken = { headers: { 'content-Type': 'application/json', Authorization: 'Bearer ' + token } };
        return this.http.post(ChangePassword,formChangePassword,this.optionWithToken);
    }
    ResetPasswordForm(formChangePassword){
        const token = sessionStorage.getItem('_enc-yek');
        this.optionWithToken = { headers: { 'content-Type': 'application/json', Authorization: 'Bearer ' + token } };
        return this.http.post(ChangePassword,formChangePassword,this.optionWithToken);
    }
    CheckingTenant(body){
        let tenantName ={ "tenancyName" : body };
        return this.http.post(CheckingTenant,tenantName,this.optionWithToken);
    }
}
