import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { SampleComponent } from './sample.component';
import { MatIconModule } from '@angular/material/icon';
import { AuthguardAdminService } from '../pages/authentication/Auth/authguardAdmin.service';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { SampleService } from './sample.service';

export function playerFactory() {
    return player;
  }
const routes = [
    {
        path     : 'sample',
        component: SampleComponent, resolve:{
            data:SampleService
        }, canActivate: [AuthguardAdminService]
    }
];

@NgModule({
    declarations: [
        SampleComponent
    ],
    imports     : [
        RouterModule.forChild(routes),
        
        TranslateModule,
        MatIconModule,
        FuseSharedModule,
        LottieModule.forRoot({ player: playerFactory })
    ],
    exports     : [
        SampleComponent
    ],
    providers: [
        SampleService
    ]
})

export class SampleModule
{
}
