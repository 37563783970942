
import { Injectable, EventEmitter } from '@angular/core';
import {  hubconnection } from './../../URL_API';
import * as signalR from "@microsoft/signalr";
import { DriverLocation } from 'app/main/sample/DriverLocation';

@Injectable()
export class SignalRService {

    connectionEstablished = new EventEmitter<Boolean>();
    OfferReceived = new EventEmitter<any>();
    newNotfiy = new EventEmitter<any>();

    liveStoke = new EventEmitter<any>();
    Livecripto = new EventEmitter<any>();
    LiveGold = new EventEmitter<any>();
    read = new EventEmitter<any>();
    Typing = new EventEmitter<any>();

    LogOut1 = new EventEmitter<any>();

    start = new EventEmitter<any>();
    end = new EventEmitter<any>();
    cancel = new EventEmitter<any>();

    Onlineusers: any[];
    ConectionId: any[];

    onlineList = new EventEmitter<any>();
    ConectionsId = new EventEmitter<any>();
    

    private connectionIsEstablished = false;
    _hubConnection = hubconnection;
    id = sessionStorage.getItem('id') || localStorage.getItem('id');
    constructor() {
        hubconnection.onclose(expction => {
            this.StartConnection();
        });
    }

    StartConnection() {
        try {
            hubconnection.start();
            this.addtoServer(1);
            this.closeConnectionOn();
            this.TripOffer();
            this.starttrip();
            this.canceltrip();
            this.endtrip();
            this.getconnection();
        }
        catch { }
    }

    public addtoServer = (id: number) => {
        hubconnection.on("WelcomeMethodName", (data) => {
            sessionStorage.setItem('_connictionId',data)
            hubconnection.invoke("GetDataFromClient", id, data).catch();
        });
    }
    
    TypingTheMessage(data : DriverLocation) {                       
        hubconnection.invoke('TripOffer',data);
    }
    checkconnection(data : number) {                       
        hubconnection.invoke('CheckConnection',data);
    }
    private getconnection(): void {
        hubconnection.on('connections', (data: any) => {
            this.onlineList.emit(data);
        });
    }
    private closeConnectionOn(): void {
        hubconnection.on('connections', (data: any) => {
            this.ConectionId = data;
            this.ConectionsId.emit(this.ConectionId);
        });
    }

    private TripOffer(): void {
        hubconnection.on('TripOffer', (data: any) => {
            this.LogOut1.emit(data);
        });
    }
    private starttrip(): void {
        hubconnection.on('StartTrip', (data: any) => {
            this.start.emit(data);
        });
    }

    private canceltrip(): void {
        hubconnection.on('CancelTrip', (data: any) => {
            this.cancel.emit(data);
        });
    }

    private endtrip(): void {
        hubconnection.on('EndTrip', (data: any) => {
            this.end.emit(data);
        });
    }
}
