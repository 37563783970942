import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { userDetails } from 'app/main/Modules/userDetails';
import { AuthService } from 'app/main/pages/authentication/Auth/auth.service';
import { identity } from 'lodash';

@Component({
    selector       : 'fuse-navigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit
{
    @Input()
    layout = 'vertical';
    childrentoremove:any[]=[];
    acceptChild:any[]=[];

    @Input()
    navigation: any;
    userDetails:userDetails;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private AuthService:AuthService

    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        if(sessionStorage.getItem('key') || localStorage.getItem('key')){

            this.userDetails=this.AuthService.userDetails();

        }
        else{
      
            this.userDetails = new userDetails('','','','','','','','','','','', '')
    
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();

        //console.log(this.navigation,'navigation')

        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
         });


         this.navigation.forEach((parent) => {
             if(parent.children && parent.role){
                 parent.children = parent.children.filter(item => this.userDetails.Permissions.indexOf(item.role) != -1);

             }else{
                 if(parent.role){
                     if(this.userDetails.Permissions.indexOf(parent.role) == -1){
                         this.navigation = this.navigation.filter(p => p != parent);
                     }
                 }
             }
         });

         this.navigation = this.navigation.filter(p => p.children?.length != 0);

         


         //console.log(this.userDetails.Permissions,'Permissions')
         
        //  this.navigation.forEach(element => {
        //     //console.log(element,'element')
        //     if(element.children){
        //         element.children.forEach(subelement => {
        //             if(subelement.role == 'Pages.Reason'){
        //               //  console.log(subelement,'hh');
        //             }
        //             //console.log(subelement,'subelement')

        //             if(subelement.role){

        //                 if(this.userDetails.Permissions.indexOf(subelement.role) == -1){
        //                    // console.log(subelement.role,'subelement.role');
        //                    console.log(element.children,'splice before')
        //                     element.children.splice(element.children.indexOf(subelement),1);
        //                     console.log(element.children,'splice after')
        //                 }
        //                 // var itemNav = this.userDetails.Permissions.find((item) => {
        //                 //     if(item != subelement.role){
        //                 //         console.log(subelement.role,'subelement.role')
        //                 //     }
                            
        //                 // });
        //                 // if(itemNav){
        //                 //     this.acceptChild.push(itemNav);
        //                 // }
        //                 //console.log(subelement,'subelementBeforeRemove')

        //                 // if( this.userDetails.Permissions.indexOf(subelement.role) == -1){
        //                 //     //console.log(subelement,'subelement')
        //                 //     //element.children.splice(element.children.indexOf(subelement),1);
        //                 //     // this.childrentoremove.find((item) => {

        //                 //     // });
        //                 //     //this.childrentoremove.push(subelement);
        //                 // }
        //             }
        //         });
        //     }
        //     else{
        //         if(element.role){
                
        //             if(this.userDetails.Permissions.indexOf(element.role) == -1){
        //                 this.navigation.splice(this.navigation.indexOf(element),1);
        //             }
        //         }
        //     }
        //  });
        //  this.navigation.forEach((ne) => {
        //      if(ne.children){
        //         this.acceptChild.forEach((e) => {
        //             var item = ne.children.find((el) => el.role == e);
        //             if(item){
        //                 console.log(item,'item');
        //             }
                    
        //         })
        //      }
        //  })
        //  this.acceptChild.forEach((e) => {
        //     this.navigation.forEach((ne) => {
        //         if(ne.children){
        //             var fi = ne.children.find((item) => item.role != e);
        //             console.log(fi)
        //             // if(!fi){
        //             //     var i = ne.children.find((item) => item == fi);
        //             //     console.log(i,'i');
        //             // }
                    
        //             //if(fi)
        //         }
        //     })
        //  })
        //  this.navigation.forEach(e => {
        //      if(e.role && e.children){
        //         e.children.forEach(se => {
        //             var itemNav = this.acceptChild.find((item) => item === se.role);
        //             console.log(itemNav);
        //             if(!itemNav){
        //               console.log(itemNav)
        //                 //e.children.splice(e.children.indexOf(se),1);
        //             }
        //          })
        //          if(!e.children){
        //              console.log(e,'remove parent')
        //          }
        //      }


        //  })

        //  this.navigation.forEach(nav => {
        //      if(nav.children){
        //         nav.children.forEach(sub => {
        //             var itemNav = this.acceptChild.find((item) => item == sub.role);
        //             if(!itemNav){
        //                 //console.log(sub,'itemNav');
        //                 var t = nav.children.indexOf(sub);
        //                 var y = nav.children[t];
        //                 console.log(t,'t')
        //                 if(nav.role){
        //                     nav.children.splice(y);
        //                 }
                        
        //                 //nav.children.splice(nav.children.indexOf(sub),1);
        //             }
        //         })
        //      }
        //  })

        //   this.childrentoremove.forEach(element => {
            
        //      const number = this.navigation[0].children.indexOf(element);
        //     this.navigation[0].children.splice(number, 1);
        //   });
    }
    LogOut(){
        this.AuthService.logout();
    }
}
