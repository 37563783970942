<div class="navbar-scroll-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">

    <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar">

        <div class="avatar-container" [routerLink]="['/apps/Profile']" routerLinkActive="router-link-active"  [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <mat-icon class="User-icon w-15-p user-profile profile-icon" svgIcon="driver_Sidenav"></mat-icon>

        </div>
        <div *ngIf="userDetails" class="h3 username" style="color: #002D50 !important;">{{userDetails.fullName}}</div>

    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

</div>
<div class="bottom-logo">
    <img src="/assets/images/backgrounds/Group 152.svg" alt="" class="bottom-logout-img">
    <button class="bottom-logout" (click)="LogOut()"><mat-icon role="img" class="mat-icon notranslate exit_to_app-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">exit_to_app</mat-icon><span>تسجيل الخروج </span></button></div>